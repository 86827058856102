<template>
    <Sobras :matricula="currentUser.matricula"/>
</template>

<script lang="js">
    
    
    import Sobras from '../../components/consulta/Sobras.vue'

    export  default {
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { Sobras }
    }

</script>
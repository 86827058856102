import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const SOBRAS_API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/sobra`;

class SobrasService {

    getSobras(matricula, ano) {
        return axios.get(SOBRAS_API_BASE_URL + RESOURCE_V1 +"/"+ matricula +"/"+ano);
    }
}

export default new SobrasService();
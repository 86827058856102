<template>
    <div class="card">
        <h5>Sobra</h5>
        
        <div class="card">
            <div class="formgroup-inline">
                <div class="field">
                    <div class="col-12 md:col-10">
                        <InputMask mask="9999"  v-model="ano" name="ano" id="anoFiltro" max="2050" min="2000" placeholder="Ano"></InputMask>
                    </div>
                </div>  
                <div class="field">
                    <div class="col-12 md:col-10">
                        <Button label="Consultar" icon="pi pi-search" @click="getSobras()"></Button>  
                    </div>
                </div>
                
            </div>
        </div>

        <DataTable :value="sobras" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="stack"
					:globalFilterFields="['codigoFamilia','produto','quantidade','valor','valorAntecipado', 'valorTotal']" >
            <template #header>
                <div class="flex justify-content-between flex-column sm:flex-row">
                    <Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                    <span class="p-input-icon-left mb-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filters1['global'].value" placeholder="Procurar" style="width: 100%"/>
                    </span>
                </div>
            </template>
            <template #empty>
               Nenhum registro encontrado.
            </template>
            <template #loading>
                Carregando dados. Por favor, aguarde!
            </template>
            <Column field="codigoFamilia" header="Cód. Família" style="text-align: center; min-width:12rem; " sortable="true">
                <template  #body="{data}">
                    {{data.codigoFamilia}}
                </template>
            </Column>
            <Column field="produto" header="Produto" style="text-align: leftr; min-width:6rem" sortable="true">
                <template  #body="{data}">
                    {{data.produto}}
                </template>
            </Column>
            <Column field="quantidade" header="Quantidade" style="text-align: right; min-width:12rem" sortable="true">
                <template  #body="{data}">
                    {{data.quantidade}} {{data.unidadeDeMedida}}
                </template>
            </Column>
            <Column field="valor" header="Valor" style="text-align: right; min-width:10rem" sortable="true">             
                <template  #body="{data}">
                    {{formatCurrency(data.valor)}}
                    <!-- {{valor}} -->
                </template>
            </Column>
            <Column header="Pago Antecipado" field="valorAntecipado" style="text-align: right; min-width:10rem" sortable="true">
                <template  #body="{data}">
                    {{formatCurrency(data.valorAntecipado)}}
                    <!-- {{valorAntecipado}} -->
                </template>
            </Column>
            <Column header="Total" field="valorTotal" style="text-align: right; min-width:10rem" sortable="true">
                <template  #body="{data}">
                    {{formatCurrency(data.valorTotal)}}
                    <!-- {{valorTotal}} -->
                </template>
            </Column>
        </DataTable>

    </div>     
</template>

<script >
    import SobrasService from '../../service/SobrasService'
    import {FilterMatchMode,FilterOperator} from 'primevue/api'

    export default {
        name: 'Sobras',
         props: {
			matricula: {
				type: Number,
				required: true
			}
		},
        data() {
            return {
                sobras: [],
                ano: 2023,
                matriculaCooperado: null,
                filters1: null,
				filters2: {},
				loading1: true,
				loading2: true, 
            }
        },

		created() {
			this.getSobras();
            this.initFilters1();
		},
        mounted() {
            this.loading1 = false;
			this.loading2 = false;
		},
        methods: {
            formatCurrency(value) {
                console.log(value);
				return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
			},

            getSobras() {
                if (this.ano != null) {
                    this.loading1=true;

                    this.sobras = [];

                    SobrasService.getSobras(this.$props.matricula, this.ano)
                    .then((response) => {
                        if (response.data != null && response.data != "") {
                            this.sobras = response.data;
                        }
                    })
                    .finally(()=>{
                        this.loading1=false;
                    });       
                }
                
            },

            initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'codigoFamilia': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'produto': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'quantidade': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'valor': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},					
					'valorAntecipado': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'valorTotal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				}
			}, 
            clearFilter1() {
				this.initFilters1();
			},
/*             formatDate(data) {
                var dataPadrao = new Date(data.data);
                var d = dataPadrao.toLocaleDateString("pt-BR");
                var h = dataPadrao.toLocaleTimeString("pt-BR"); 
                return d + " " + h;
			}, */
            
            // formatCurrency(value) {
            //     alert(value);
			// 	return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
			// },

            

        }     
       
    }
</script>